import React from 'react';
import radiostation from '../assets/images/radiostation.jpg'

function Bonner() {
  return (
    <div style={{width:'auto', marginTop:'20px',marginBottom:'80px', padding:'20px', backgroundImage: `url(${radiostation} )`,  backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat', backgroundPosition: 'center', textAlign:'center' }}>
    <p style={{fontSize:'25px', color:'#ffffff'}}>A source of Enlightenment to unbelievers. Creating a better society through Music Godly Entertainment.</p>
   </div>
  )
}

export default Bonner