import React from 'react';
function Contact() {
  return (
    <div>
      <div className='contactDetails'>
        <h3>Contact</h3>
        <div className='contactInfo'>
        <div>
          <p>Email: ivanhall20@yahoo.com</p>
          <p>Tel: 876-384-8037</p>
          <p> Tel: 876-330-9404</p>
        </div>
        
        </div>
      </div>
    </div>
  )
}

export default Contact