import React from 'react'

function Footer() {
  return (
    <div style={{backgroundColor:'#815939', width:'100%', height:'50px', textAlign:'center', alignContent:'center', color:'white', }}>
        
        <p>Powered By Gold Radio Jamaica | An Oasis in the Desert.</p>
        </div>
  )
}

export default Footer