import React from 'react'
import '../pages/pagesStyles.css'
function About() {
  return (
    <div className='aboutStyles'>
      <div className="aboutText" style={{ height:'900px', lineHeight:'30px', }}>
        <h2 style={{marginTop:'20px', margin:'10px'}}>About</h2>
<p style={{marginTop:'20px', margin:'10px'}}>GOLD FM. AN OASIS IN THE DESERT OF TIME GOLD FM IS THE VISION OF CEO PASTOR IVAN HALL TO PROVIDE ENCOURAGEMENT AND STRENGTH FOR BELIEVERS. A SOURCE OF ENLIGHTENMENT TO UNBELIEVERS. CREATING A BETTER SOCIETY THROUGH MUSIC AND GODLY ENTERTAINMENT. GOLD WAS FOUNDED IN THE YEAR 2015 FEBRUARY AND HAS BEEN A SOURCE OF BLESSING TO THOUSANDS AROUND THE WORLD. OUR VISION IS TO LINK WITH MAJOR MEDIA HOUSES AND BRING THE GOSPEL TO THE LOST AND DYING</p>
      </div>
    </div>
  )
}

export default About